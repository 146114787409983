import { Divider, Heading } from "@chakra-ui/react"
import { WindowLocation } from "@reach/router"
import React, { useContext } from "react"
import Layout from "../components/layout/layout"
import SignUp from "../components/signupForm"
import UserContext from "../context/userContext"

export default function Aanmelden({
  location,
  pageContext,
}: {
  location: WindowLocation
  pageContext: any
}) {
  const { tags } = pageContext
  const { state } = useContext(UserContext)

  return (
    <Layout title="Aanmelden" location={location}>
      <div className="page">
        <Heading>Aanmelden</Heading>
        {!state?.gpsUpdated && (
          <>
            <p style={{ margin: "1rem 0 " }}>
              Jouw stalletje langs de weg, jouw (eenmalige) afhaalmoment zonder
              stalletje of jouw professionele boerderijwinkel online promoten op
              Fairsy.nl kan op 3 manieren.
            </p>
            <ol style={{ marginLeft: "1rem" }}>
              <li>
                <b>Stalletje langs de weg (gratis)</b>
                <br />
                Verkoop je verse items of spulletjes via je stalletje langs de
                weg? Meld je dan gratis aan. Voeg foto's, openingstijden en geef
                altijd even aan zodra u verse artikelen heeft.
              </li>
              <li>
                <b>(eenmalig) Afhaalmoment (gratis)</b>
                <br />
                Wilt u GEEN kraampje langs de weg, maar wel (eenmalig) verkopen
                op een door u aangegeven afhaalmoment? Meld u nu dan gratis aan.
                Ideaal als u overschot in uw moestuin of fruitgaard heeft.
              </li>
              <li>
                <b>Boerderijwinkel of vergelijkbare winkel (gratis)</b>
                <br />
                Promoot uw professionele groente-, fruit- of boerderijwinkel op
                Fairsy.nl. Voeg openingstijden en foto's toe. Promoot uw acties
                en verse artikelen bij onze bezoekers.
              </li>
            </ol>
            <br />
            <div>
              Nu aanmelden en goedkeuring door ons krijgt u logingegevens
              waarmee u alles binnen uw account kunt aanpassen (ook de
              openingstijden) en verse nieuwsberichten kan plaatsen.
            </div>
            <br />
          </>
        )}
        <Divider />
        <SignUp products={tags} isLocationSpotting={!!state?.gpsUpdated} />
      </div>
    </Layout>
  )
}
