import { faMinusSquare } from "@fortawesome/free-solid-svg-icons/faMinusSquare"
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons/faPlusSquare"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useContext, useState } from "react"
import UserContext from "../context/userContext"
import useIsLoggedIn from "../hooks/useisLoggedIn"
import { userFetch } from "../utility/userFetch"
import { trackCustomEvent } from "../utility/tracking"

export function KioskStar({ kioskId }: { kioskId: string }) {
  const ls = typeof localStorage !== "undefined" ? localStorage : undefined

  const isLoggedIn = useIsLoggedIn()
  const { state, dispatch } = useContext(UserContext)
  const likes = ls?.getItem("likes")?.split(";") ?? state?.likes ?? []
  const [isLiked, setIsLiked] = useState(likes.indexOf(kioskId) >= 0)

  const onclick = useCallback(
    x => {
      x.preventDefault()
      x.stopPropagation()

      const likesTotal = isLiked
        ? [...likes.filter(x => x !== kioskId)]
        : [...likes, kioskId]

      if (!isLiked) {
        trackCustomEvent({
          category: "Social",
          action: "Like",
          value: likesTotal.length,
        })
      }
      const likesStrings = likesTotal.join(";")
      dispatch({ likes: likesTotal })
      setIsLiked(!isLiked)
      try {
        ls?.setItem("likes", likesStrings)
      } catch {
        // niet iedereen kan een iphone+++ betalen
      }

      if (isLoggedIn) {
        userFetch(
          "POST",
          "LikesTrigger",
          state?.jwt,
          JSON.stringify({ likes: likesStrings })
        )
      }
    },
    [kioskId, ls, isLiked, isLoggedIn]
  )

  return (
    <div
      style={{
        border: "1px solid #CCC",
        borderRadius: "1rem",
        cursor: "pointer",
        padding: "0 1rem",
        fontSize: "-70%",
        backgroundColor: isLiked ? "#71ba6f " : "transparent",
        display: "inline-flex",
        userSelect: "none",
        verticalAlign: "center",
      }}
      onClick={onclick}
      className="tag"
    >
      <span
        style={{
          width: "var(--mobileWith,1.125em)",
          height: "1rem",
          marginRight: "1rem",
        }}
      >
        <FontAwesomeIcon
          icon={isLiked ? faMinusSquare : faPlusSquare}
          title="Star"
          size="sm"
          color="#33423d"
        />
      </span>
      <span style={{ padding: "0 , 5px" }}>fiets route</span>
    </div>
  )
}
