import { Heading } from "@chakra-ui/react"
import { navigate } from "gatsby"
import React, { CSSProperties, useCallback } from "react"
import { Kiosk } from "../interfaces/kiosk"
import { firstWords } from "../utility/string"
import { KioskCardOpeningHours } from "./KioskCardOpeningHours"
import { KioskCardProducts } from "./KioskCardProducts"
import { KioskCardSocialMedia } from "./KioskCardSocialMedia"
import KioskPicture from "./kioskPicture"
import { KioskRowAddress } from "./KioskRowAddress"
import { KioskStar } from "./kioskStar"

export interface IPropsKioskCard {
  a: Kiosk
  data?: any
  width: string
  isStandAlone?: boolean
  style?: CSSProperties
  onClick?: (currentKioskId: string) => void
  reset?: () => void

  onHover?: (input: string | undefined) => void
}
export default function KioskCard({
  a,
  data,
  width,
  isStandAlone,
  style,
  onClick,
  reset,
  onHover,
}: IPropsKioskCard) {
  const isStandAloneFull = isStandAlone ?? !a.url

  const cardClick = useCallback(
    event => {
      event.preventDefault()
      event.stopPropagation()
      if (onClick) {
        onClick(a.id)
      } else {
        if (a.url) navigate(a.url)
      }
    },
    [onClick, a.url]
  )

  const cardMoveClick = useCallback(
    event => {
      event.preventDefault()
      event.stopPropagation()
      if (a.url) {
        if (reset) {
          reset()
        }
        navigate(a.url)
      } else {
        if (onClick) {
          onClick(a.id)
        }
      }
    },
    [onClick, a.url]
  )

  const readMore = firstWords(a.information.description, 80)
  const description =
    isStandAloneFull || !readMore ? (
      <p>{a.information.description}</p>
    ) : (
      <p>
        {readMore}...{" "}
        <a href={a.url || "/"} onClick={cardClick}>
          <span style={{ textDecoration: "underline" }}>Lees verder</span>
        </a>
      </p>
    )

  const containerStyle: CSSProperties = {
    ...style,

    maxWidth: width,
    width: width,
    cursor: isStandAloneFull ? undefined : "pointer",

    display: "flex",
    flexDirection: "row",
  }

  const hasPicture = a.picture || !isStandAlone
  const onMouseEnter = useCallback(() => {
    if (onHover) {
      onHover(a.id)
    }
  }, [onHover, a.id])

  const onMouseLeave = useCallback(() => {
    if (onHover) {
      onHover(undefined)
    }
  }, [onHover])
  return (
    <div
      className="kioskContainer kioskRow"
      style={containerStyle}
      onClick={!isStandAloneFull ? cardClick : undefined}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {hasPicture && (
        <div
          style={{
            width: "20%",
            margin: "0.4rem 1rem 1rem 0",
            minWidth: "117.5px",
          }}
          className="mobileCenter"
        >
          <KioskPicture picture={a.picture} size={2} />
        </div>
      )}
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          {a.url && (
            <a
              href={a.url}
              onClick={cardMoveClick}
              className="hoverUnderline"
              style={{ textDecoration: "none!important" }}
            >
              <Heading size="md">{a.name}</Heading>
            </a>
          )}
          {!a.url && (
            <Heading size="md" as="h1">
              {a.name}
            </Heading>
          )}
        </div>
        {description} {a.information.acceptedPayments}
        {isStandAloneFull && <KioskCardOpeningHours a={a} />}
        <KioskCardProducts kiosk={a} />
        <KioskRowAddress kiosk={a} />
        <KioskCardSocialMedia kiosk={a} />
      
        <div style={{ marginTop: "0.5rem" }}>
          <KioskStar kioskId={a.id} />
        </div>
      </div>
    </div>
  )
}
