import { Heading, useToast } from "@chakra-ui/react"
import { navigate } from "gatsby-link"
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import UserContext from "../context/userContext"
import { Kiosk } from "../interfaces/kiosk"
import { isMobile } from "../utility/detection"
import { apiFetch } from "../utility/userFetch"
import KioskCard from "./kioskCard"
import GeneralInformationEditor from "./kioskEditor/generalInformationEditor"

export default function SignUp({
  products,
  isLocationSpotting,
}: {
  products: string[]
  isLocationSpotting?: boolean
}) {
  const toast = useToast()
  const { state } = useContext(UserContext)
  const form = useForm()
  const { watch, setValue } = form
  useEffect(() => {
    if (state?.gpsUpdated) {
      apiFetch(
        `location?lat=${state.coordinates?.lat}&lon=${state.coordinates?.lon}`,
        {
          method: "GET",
        }
      )
        .then(x => x.json())
        .then(a => {
          setValue("Adres", a.streetAddress)
          setValue("Postcode", a.zipcode)
          setValue("Plaats", a.city)
          setValue("Province", a.province)
        })
    }
  }, [state?.gpsUpdated])
  const [isLoading, setIsLoading] = useState(false)
  const onSubmit = (data: any) => {
    setIsLoading(true)
    console.log(data)

    const payloadString = JSON.stringify({
      ...data,
      products: Array.isArray(data.products)
        ? data.products.map((x: any) => x.value)
        : [],
    })

    const formData = new FormData()
    formData.append("json", payloadString)

    if (data.photo) {
      for (const file of data.photo) {
        formData.append("files", file, file.name)
      }
    }

    console.log(payloadString)

    apiFetch("SignUp", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.x
      body: formData,
      headers: {}, // it only  works without content type header
    })
      .then(a => {
        toast({
          title: "Aanmelding is verstuurd.",
          description: " Wij streven er naar om binnen een week te reageren.",
          status: "success",
          duration: 9000,
          isClosable: true,
        })
        navigate("/")
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const productsInput = watch("products")
  const kiosk: Kiosk = {
    id: "-1",
    name: watch("naam")?.length > 0 ? watch("naam") : "Naam van het stalletje",
    urlName:
      watch("naam")?.length > 0 ? watch("naam") : "Naam van het stalletje",
    address: {
      province: watch("Province"),
      streetAddress: watch("Adres"),
      city: watch("Plaats"),
      zipcode: watch("Postcode"),
      area: "",
      description: watch("TravelDirections"),
    },
    products: Array.isArray(productsInput)
      ? productsInput.map((x: any) => x.value)
      : [],
    information: {
      isGoldMember: false,
      description: watch("diensten"),
      hours: [],
      website: watch("Website"),
      facebook: watch("Facebook"),
      instagram: watch("Instagram"),
      deliveryText: watch("DeliveryText"),
      whatsappNumber: watch("whatsappNumber"),
    },
  }

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <GeneralInformationEditor
          isLoading={isLoading}
          products={products}
          formControl={form}
          onSubmit={onSubmit}
          isLocationSpotting={isLocationSpotting}
        />
      </div>
      {!isMobile() && (
        <div
          style={{
            flexGrow: 1,
            width: "40%",
            margin: "2rem 0 2rem 2rem",
          }}
        >
          <Heading size="lg" style={{ marginBottom: "1rem" }}>
            Voorbeeld
          </Heading>
          <KioskCard
            a={kiosk}
            width="100%"
            isStandAlone={true}
            style={{ top: "1rem", position: "sticky" }}
          />
          {}
        </div>
      )}
    </div>
  )
}
