import React from "react"

export default function KioskPicture({
  picture,
  size,
  loading,
}: {
  picture: undefined | string
  size: number
  loading?: "eager" | "lazy"
}) {
  if (!picture) {
    return <></>
  }

  const width = (235 / size).toString() + "px"
  const height = (175 / size).toString() + "px"

  return (
    <div style={{ width, height }}>
      <img
        src={"https://fairsynl.blob.core.windows.net/pictures/" + picture}
        alt={"foto"}
        style={{ borderRadius: "1.375em", objectFit: "cover", width, height }}
        loading={loading ?? "lazy"}
      />
    </div>
  )
}
