export const firstWords = (input: undefined | string, length: number) => {

  if (!input || input.length <= length) {
    return undefined;
  }
  const spacePos = input?.substring(length).indexOf(' ');
  if (!spacePos) {
    return input.substring(0, length);
  }

  const endPos = (spacePos || -1) < 0 ? 0 : (length + spacePos);

  return input.substring(0, endPos);

}

export const firstWordsFallback = (input: undefined | string, length: number) => {

  const result = firstWords(input, length)
  return (result ?? "").length > 0 ? result : input;
}

const formatter = new Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR'
})
export const euro = (input: number) => {
  return formatter.format(input);
}
