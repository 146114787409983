import React, { CSSProperties } from "react"
import { Kiosk } from "../interfaces/kiosk"
import { daysOfWeek } from "../utility/consts"
import KioskRow from "./kioskRow"

export function KioskCardOpeningHours({
  a,
  style,
}: {
  a: Kiosk
  style?: CSSProperties
}) {
  const openingHours = a.information.hours?.some(a => !!a)

  return openingHours || a.information.hoursDescription ? (
    <KioskRow label="Opening" style={style}>
      {openingHours &&
        daysOfWeek.map((x, i) => (
          <div
            key={i + "day"}
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                flex: "1",
              }}
            >
              {x}
            </div>
            <div
              style={{
                flex: "1",
              }}
            >
              {a.information.hours[i] ? a.information.hours[i] : "gesloten"}
            </div>
          </div>
        ))}
      {a.information.hoursDescription && <>{a.information.hoursDescription}</>}
    </KioskRow>
  ) : (
    <></>
  )
}
