import { Link } from "gatsby"
import React, { CSSProperties } from "react"
import { Kiosk } from "../interfaces/kiosk"
import KioskRow from "./kioskRow"

export function KioskCardProducts({
  kiosk,
  style,
}: {
  kiosk: Kiosk
  style?: CSSProperties
}) {
  return kiosk.products && kiosk.products.length > 0 ? (
    <KioskRow label="Producten" style={style}>
      {kiosk.products
        ?.map<React.ReactNode>((a, id) =>
          typeof a === "string" ? (
            <React.Fragment key={id}>{a}</React.Fragment>
          ) : (
            <Link to={a.url} style={{ textDecoration: "underline" }} key={id}>
              {a.name}
            </Link>
          )
        )
        ?.reduce(
          (prev, curr) => (prev ? [prev, ", ", curr] : [curr]),
          undefined
        )}{" "}
    </KioskRow>
  ) : (
    <></>
  )
}
