import { faShareSquare } from "@fortawesome/free-solid-svg-icons/faShareSquare"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback } from "react"
import { Kiosk } from "../interfaces/kiosk"
import { iconCss } from "../utility/css"
import { getStreetAddress } from "../utility/kioskTransforms"
import KioskRow from "./kioskRow"
import { trackCustomEvent } from "../utility/tracking"

function GoogleLink({ children, kiosk }: { children: any, kiosk: Kiosk }) {
  return kiosk.address.coordinates ? (<a
    className="link"
    href={`https://www.google.nl/maps/place/${getStreetAddress(kiosk)}/${kiosk.address.coordinates.lat
      },${kiosk.address.coordinates.lon}`}
    rel="nofollow noreffer"
    target="noblank"
    title={`maps`}
    style={{
      padding: "1px",
    }}
  >{children}</a>) : <>{children}</>;
}

export function KioskRowAddress({ kiosk }: { kiosk: Kiosk }) {
  const clickShare = useCallback(() => {
    try {



      const shareData = {

        title: kiosk.name,
        text: kiosk.information.description,
        url: kiosk.url ? "https://www.fairsy.nl" + kiosk.url : window.location.href
      }
      navigator.share(shareData);

      trackCustomEvent({
        category: "social",
        action: "share",
      })
    } catch (err) {

    }


  }, [kiosk.url, kiosk.name])

  return (
    <KioskRow label="Adres">
      {typeof navigator !== 'undefined' && !!navigator.canShare && (

        <FontAwesomeIcon
          icon={faShareSquare}
          size="sm"
          width="15"
          height="15"
          style={{ ...iconCss, float: "right" }}
          onClick={clickShare}
        />

      )}
      <GoogleLink kiosk={kiosk}>
        {getStreetAddress(kiosk)}
      </GoogleLink>
      {kiosk.address.description && ` (${kiosk.address.description})`}
    </KioskRow>
  )
}
