export const isIOS = () => {
  return [
    'iPhone Simulator',
    'iPod Simulator',
    'iPhone',
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export function isMobile() {
  return (typeof window !== 'undefined') && (isIOS() || (window.innerWidth <= 800) && (window.innerHeight <= 1000));
}