import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons/faWhatsapp"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback } from "react"
import { Kiosk } from "../interfaces/kiosk"
import UtmSource from "../utility/urlBuilder"
import KioskRow from "./kioskRow"
import { trackCustomEvent } from "../utility/tracking"
export function KioskCardSocialMedia({ kiosk }: { kiosk: Kiosk }) {


  const clickWA = useCallback(() => {
    trackCustomEvent({
      category: "social",
      action: "WhatsApp",
    })
  }, [])

  return (
    <div>
      {kiosk.information.website && (
        <KioskRow label="Website">
          <a
            href={UtmSource(kiosk.information.website)}
            title={`${kiosk.name} website`}
            rel="nofollow"
            target="noblank"
            style={{
              textDecoration: "underline",
            }}
          >
            {kiosk.information.website}
          </a>
        </KioskRow>
      )}
      {(kiosk.information.facebook ||
        kiosk.information.instagram ||
        kiosk.information.twitter ||
        kiosk.information.whatsappNumber) && (
          <div style={{ height: "2rem" }}>
            {kiosk.information.facebook && (
              <a
                href={kiosk.information.facebook}
                rel="nofollow noreffer"
                target="noblank"
                title={`${kiosk.name} facebook`}
                style={{ padding: "1px", marginRight: "0.5rem" }}
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  size="sm"
                  width="15"
                  height="15"
                />
              </a>
            )}
            {kiosk.information.whatsappNumber && (
              <a
                href={
                  "https://wa.me/" +
                  kiosk.information.whatsappNumber +
                  "/?text=" +
                  (typeof window !== "undefined"
                    ? window.location.href
                    : "https://www.fairsy.nl")
                }
                onClick={clickWA}
                rel="nofollow noreffer"
                target="noblank"
                title={`${kiosk.name} whatsapp`}
                style={{ padding: "1px", marginRight: "0.5rem" }}
              >
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  size="sm"
                  width="15"
                  height="15"
                />
              </a>
            )}

            {kiosk.information.instagram && (
              <a
                href={kiosk.information.instagram}
                rel="nofollow noreffer"
                target="noblank"
                title={`${kiosk.name} instagram`}
                style={{ padding: "1px", marginRight: "0.5rem" }}
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="sm"
                  width="15"
                  height="15"
                />
              </a>
            )}


          </div>
        )}
    </div>
  )
}
