import { Kiosk } from "../interfaces/kiosk";

export function getStreetAddress(kiosk: Kiosk) {

  return [
    kiosk.address.streetAddress,
    !!kiosk.address.zipcode
      ? kiosk.address.zipcode + " " + kiosk.address.city
      : kiosk.address.city,
    kiosk.address.province,
  ].filter(x => x && x.trim().length > 0).join(", ")
}