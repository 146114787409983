import React, { CSSProperties } from "react"

export default function KioskRow({
  label,
  children,
  style,
}: {
  label: string
  children: any
  style?: CSSProperties
}) {
  return (
    <div
      className="kioskRow"
      style={{
        display: "flex",
        margin: "0.3rem 0",
        ...style,
      }}
    >
      <div style={{ fontWeight: 600, flexBasis: "20%" }}>{label}:</div>
      <div style={{ flexBasis: "80%" }}>{children}</div>
    </div>
  )
}
